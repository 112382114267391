<template>
  <div class="bind_otp_confirm">
    <div class="bind_otp_confirm_icons mt_3em mb_2em">
      <img src="@/assets/images/login/ic_security_center_captcha.webp" />
    </div>
    <div class="mb_3em">
      <div>请从谷歌验证APP获取谷歌验证码</div>
    </div>
    <div class="bind_otp_confirm_confirm ta_left ml_2em mt_2em">
      <div class="bind_otp_confirm_confirm_title mb_1em">谷歌二次验证码</div>
      <div class="bind_otp_confirm_confirm_content">
        <input v-for="(item, index) in confirmCode" :key="index" :ref="`confirmCode${index}`" type="text" class="custom_input square" v-model="confirmCode[index]" @keyup="checkConfirm" @focus="setCurrentFocus($event, index)" />
      </div>
    </div>
    <div class="textBtn ta_center mt_2em" @click="goToHelp">查看教程</div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "BindOTPDownload",
  data() {
    return {
      confirmCode: ["", "", "", "", "", ""],
      oldConfrimCode: [],
      currentFocus: "",
    };
  },
  methods: {
    ...mapActions("auth", ["googleSecondConfrim"]),
    goToHelp() {
      this.$router.push("/bindOTP/help");
    },
    checkConfirm(event) {
      // 避免输入数字以外的字
      if ((event.keyCode < 48 && event.keyCode > 57) || (event.keyCode < 96 && event.keyCode > 105)) {
        event.preventDefault();
      }
      if (this.currentFocus < 5) {
        let el = this.$refs[`confirmCode${this.currentFocus + 1}`][0];
        el.focus();
      } else if (this.currentFocus === 5) {
        this.sendConfirm();
      }
    },
    setCurrentFocus(event, index) {
      event.target.selectionStart = event.target.value.length;
      event.target.selectionEnd = event.target.value.length;
      this.currentFocus = index;
    },
    sendConfirm() {
      let code = this.confirmCode.join("");
      this.googleSecondConfrim(code)
        .then((res) => {
          if (res.code === "ok") {
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    confirmCode: {
      handler(val) {
        val.forEach((item, index) => {
          let oldValue = this.oldConfrimCode[index];
          if (item.length > 1) {
            this.confirmCode[index] = this.confirmCode[index].replace(oldValue, "");
          }
        });
        this.oldConfrimCode = JSON.parse(JSON.stringify(val));
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.bind_otp_confirm {
  padding: 0 1em;
  position: relative;
  &_icons {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 3em;
    }
    img:nth-child(2) {
      width: 2em;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
  &_confirm {
    &_content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      input {
        margin-right: 0.5rem;
      }
    }
  }
  &_next {
    position: fixed;
    width: calc(100% - 2rem);
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
  }
}
</style>
